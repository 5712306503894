.PageHeader {
  padding: 20px 0;
  border-bottom: 1px solid #f1f1f1;
}

.FormWizard li {
  cursor: default !important;
}

.FormBody {
  width: 67%;
  height: 660px;
}

.FormWizard li {
  cursor: pointer !important;
}

.FormBody {
  width: 67%;
  height: 660px;
}

.FormActions {
  width: 31% !important;
}
