.Wrapper {
  width: 100%;
  height: 100vh;
  padding-top: 40px;
  background-color: #0A0A5F;
}

.Logo {
  margin-top: 150px;
  display: flex;
  justify-content: center;
}

.Container {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}
