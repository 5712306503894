.Stats {
    margin-bottom: 16px;
    display: flex;
}

.Stat {
    max-width: 196px;
    height: 120px;
    padding: 24px;
    border-radius: 4px;
    background-color: #444;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 0;
    transition: background-color ease 0.2s;
}

.Stat.Active {
    background-color: #19AF81;
}

.Stat:hover {
    background-color: #19AF81;
}

.Stat + .Stat {
    margin-left: 15px;
}

.Stat > div {
    height: 23px;
    display: flex;
    justify-content: space-between;
}

.Stat .Title {
    cursor: pointer;
}

.Stat .Title div:first-child {
    font-weight: 600;
}

.Stat .Number div:first-child {
    font-size: 32px;
    font-weight: 600;
    cursor: pointer;
}

.Stat .Number .AddNew {
    cursor: pointer;
}

.DateRangeFilter {
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
}

.SelectedFilterDates {
    padding: 0 0px 0 20px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    background-color: #FFCC00;
    border-radius: 22px;
    color: #444;
}

.SelectedFilterDates img {
    margin-right: -2px;
}

.SelectedFilterDates .Date {
    margin-right: 15px;
}

.SubmitDatesBtn {
    margin-top: 15px;
    text-align: right;
}

.DateRangeFilter .CalendarIcon {
    cursor: pointer;
}