.Slides {
}

.Slide {
    width: 50%;
    display: flex;
}

.Slide .Image img {
    width: 100%;
}

.Slide .Actions {
    margin-left: 5px;
}

.Slide .Actions > div + div {
    margin-top: 5px;
}