.DateRangeFilter {
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
}

.SelectedFilterDates {
    padding: 0 0px 0 20px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    background-color: #FFCC00;
    border-radius: 22px;
    color: #444;
}

.SelectedFilterDates img {
    margin-right: -2px;
}

.SelectedFilterDates .Date {
    margin-right: 15px;
}

.SubmitDatesBtn {
    margin-top: 15px;
    text-align: right;
}

.DateRangeFilter .CalendarIcon {
    cursor: pointer;
}