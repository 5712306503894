.PasswordToggleBtn {
    position: absolute;
    top: 52px;
    right: 20px;
    z-index: 9;
    cursor: pointer;
}

.PasswordStrength {
    margin-top: 22px;
}

.Indicator {
    width: auto;
    display: flex;
    align-items: center;
}

.ProgressBar {
    width: 144px;
    height: 5px;
    margin: 4px 0 0 15px;
    border-radius: 4px;
    background-color: #fff;
}

.Progress {
    height: 100%;
}

.Level {
    width: 80px;
}

.Notes {
    margin-top: 16px;
    font-size: 11px;
}

.Notes ul li + li {
    margin-top: 12px;
}

.FormControl {
    width: 100%;
    height: 43px;
    padding: 0 10px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    background-color: #FFF;
}

.FormControl:focus,
.FormControl:active,
.FormControl:hover {
  outline: none;
  box-shadow: none;
}

.FormControl:hover {
  border-color:hsl(0, 0%, 60%);
}

.FormControl:focus, 
.FormControl:active {
  border-color: #2684FF;
}