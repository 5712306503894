.DetailOverview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.DetailOverview .Detail {
  width: 60%;
  padding: 12px 0;
  display: flex;
}

.DetailOverview .Detail div:first-child {
  width: 20%;
  font-weight: 600;
}

.DetailOverview .Detail div:last-child {
  width: 80%;
}

.ProfitLoss {
  margin-top: 40px;
}

.PageNavigation {
  padding: 0;
  margin-bottom: 10px;
}

.NavigationMenu ul {
  display: flex;
}

.NavigationMenu ul li {
  padding: 15px 40px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all ease 0.3s;
  font-weight: 600;
}

.NavigationMenu ul li:hover {
  background-color: #FBCDDB;
}

.NavigationMenu ul li.Active {
  background-color: #ee3d73;
  color: #fff;
}

.UserOverview {
  margin-top: 10px;
}

.Ledgers > div + div,
.Products > div + div,
.Transactions > div + div {
  margin-top: 60px;
}
