.FormWizard li {
  cursor: default !important;
}

.FormBody {
  width: 67%;
  height: 660px;
}

.FormWizard li {
  cursor: default !important;
}

.FormBody {
  width: 67%;
  height: 660px;
}

.FormActions {
  width: 31% !important;
}
