.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(3,3,3,0.5);
    z-index: 98;
}

.Backdrop.Dark {
    background-color: rgba(0,0,0,0.8);
}