.PageContent {
  min-height: 500px;
}

.Cards {
  margin: -7px -7px 16px -7px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.Card {
  min-width: calc((100% - 56px) / 4);
  height: 200px;
  padding: 20px;
  margin: 7px;
  border-radius: 4px;
  background-color: #fefefe;
  color: #191e3a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 0;
  transition: background-color ease 0.2s;
  border: 1px solid #e0e6ed;
  box-shadow: 3px 4px 7px -3px #bfc9d4;
}

.Icon svg {
  width: 3em !important;
  height: 3em !important;
}

.Count {
  font-size: 26px;
  font-weight: 700;
}

.Title {
  margin-top: 7px;
  font-size: 16px;
  font-weight: 400;
}
