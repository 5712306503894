.Applications {
  width: 320px;
}

.Application {
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.Application + .Application {
  margin-top: 4px;
}

.Application.Active {
  background-color: #d9d9d9;
}

.FormActions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}