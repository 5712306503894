.SignIn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.SignIn h1 {
  margin: 48px 0 0;
  color: #11ebef;
  font-size: 18px;
  font-family: "EB Garamond", serif;
  font-weight: 400;
}

.SignIn h2 {
  margin: 20px 0 0;
  font-size: 16px;
}
