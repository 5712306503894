.PageActions {
  display: flex;
  justify-content: flex-end;
}

.FormActions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.Region {
  display: flex;
  align-items: center;
}

.Region img {
  margin-right: 10px;
}