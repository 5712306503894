.NavItem {
  display: flex;
  align-items: center;
}

.NavItem + .NavItem {
  margin-top: 4px;
}

.NavItem a {
  width: 100%;
  height: 46px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 2px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  border-left: 3px solid #060818;
}

.NavItem a.active {
  background-color: #1c2835;
  border-color: #42B549;
}

.NavItem a svg {
  width: 1.5em !important;
  height: 1.5em !important;
  margin-right: 10px;
}
