.Checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.Checkbox .ControlLabel {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}

.Checkbox input {
  margin-right: 10px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Checkbox .Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: transparent;
  background-color: #E2E2E2;
}

.Checkbox input:checked ~ .Checkmark {
  background-color: #5dc900;
}

.Checkbox .Checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Checkbox input:checked ~ .Checkmark::after {
  display: block;
}

.Checkbox input:disabled ~ .ControlLabel {
  color: #9a9a9a;
}

.Checkbox input:disabled ~ .Checkmark {
  border-color: #9a9a9a;
}
