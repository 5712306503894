.Btn {
  width: auto;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  border: 0;
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
}

.Btn span {
  margin-left: 10px;
}

.Primary {
  background-color: #42B549;
}

.Secondary {
  color: #82868b;
  background-color: transparent;
  border: 1px solid #82868b;
}

.Default {
  color: #000;
  background-color: #f1f1f1;
}

.Btn[disabled] {
  opacity: 0.3;
}

.Success {
  background-color: #42B549;
}

.W100 {
  width: 100% !important;
}

.Info {
  color: #fff;
  background-color: #00cfe8;
}

.InfoM {
  height: 32px;
  padding: 0 10px;
  border-radius: 7px;
  color: #fff;
  background-color: #00cfe8;
  font-size: 12px;
  text-transform: none;
}

.Loading {
  cursor: default;
}

.LdsRing {
  display: inline-block;
  width: 23px;
  height: 23px;
  z-index: 9999;
  left: 0;
  right: 0;
}

.LdsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 1px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.LdsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.LdsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.LdsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
