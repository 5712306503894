/* Datepicker */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header__dropdown--select {
  margin-top: 5px;
}

.react-datepicker__header__dropdown--select select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.react-datepicker__month-select {
  width: 100px;
}

.react-datepicker__year-select {
  width: 70px;
}
