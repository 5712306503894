.Header {
  height: 75px;
  padding: 0 20px;
  background-color: #191e3a;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Actions ul {
  display: flex;
  color: #fff;
}

.Actions ul li {
  height: 37px;
  display: flex;
  align-items: center;
}

.Actions ul li img {
  height: 37px;
}

.Actions ul li + li {
  margin-left: 8px;
}

.Profile svg {
  margin-right: 8px;
  width: 1.5em !important;
  height: 1.5em !important;
}

.Logout {
  margin-left: 20px !important;
  cursor: pointer;
}

.Logout svg {
  width: 1.7em !important;
  height: 1.7em !important;
}
