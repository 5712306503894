.PageActions {
  display: flex;
  justify-content: flex-end;
}

.PhotosWrapper {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.PhotosWrapper .Photo {
  margin: 10px;
}

.PhotosWrapper .Photo .Image img {
  width: 200px;
  height: 250px;
  border-radius: 4px;
}

.Actions {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.Actions > div + div {
  margin-left: 5px;
}

.FormActions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
