.CalendarIcon {
  position: absolute;
  top: 47px;
  right: 20px;
  z-index: 9;
}

.FormControl {
  width: 100%;
  height: 43px;
  padding: 0 10px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #fff;
}

.FormControl:focus,
.FormControl:active,
.FormControl:hover {
  outline: none;
  box-shadow: none;
}

.FormControl:hover {
  border-color: hsl(0, 0%, 60%);
}

.FormControl:focus,
.FormControl:active {
  border-color: #2684ff;
}

.FormControl::placeholder {
  text-align: left;
}

.Horizonal {
  min-height: 65px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Horizonal .Field {
  display: flex;
  align-items: center;
}

.Horizonal .Field label {
  width: 40%;
}

.Horizonal .FormControl {
  margin-top: 0;
}

.Horizonal img {
  top: auto;
}
