.Button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #F1F1F1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Button img {
    width: 18px;
}