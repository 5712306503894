.PageActions {
  display: flex;
  justify-content: flex-end;
}

.FormActions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
