.MainNav {
  width: 240px;
  min-height: 100vh;
  padding: 17px 0;
  background-color: #060818;
}

.MainNav .Logo {
  padding: 0 10px;
}

.MainNav .Logo img {
  width: 140px;
}

.NavBar {
  margin-top: 15px;
}
