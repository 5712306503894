h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 600;
}

h1 {
  font-size: 18px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #3498db;
}

/* Custom Classes */
.Pad15 {
  padding: 15px;
}

/* PageHeader */
.PageHeader {
  height: 64px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
}

.PageHeader .ContentRight {
  display: flex;
  align-items: center;
}

.PageHeader .ContentRight .Action {
  margin-left: 8px;
}

/* Page Content */
.PageContent {
  width: 1280px;
  padding: 15px;
  margin: 40px auto;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  min-height: 600px;
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  padding: 15px 5px;
  text-transform: uppercase;
  color: #222;
  font-size: 12px;
  font-weight: 600;
}

table th span {
  width: 20px;
  display: inline-block;
}

table thead,
table tbody tr + tr {
  border-bottom: 1px solid #f7f7f7;
}

table tbody {
  color: #444;
}

table tbody tr {
  height: 43px;
  transition: background-color ease 0.3s;
}

table tbody tr:hover {
  background-color: #fafafa;
}

table tbody tr button {
  margin-right: 10px;
  height: 30px !important;
  font-size: 12px !important;
}

table .ActionsColumn {
  display: flex;
}

table .ActionsColumn > div + div {
  margin-left: 5px;
}

/* Data Table */
.DataTable.FixedWidth table tr td {
  display: flex !important;
  align-items: center;
}

.DataTable table tbody tr:first-child {
  border-bottom: 1px solid #f7f7f7;
}

.DataTable table tbody tr td:last-child {
  display: flex;
}

.DataTable table tbody tr td:last-child div + div {
  margin-left: 4px;
}

/* Table Header */
.TableHeader {
  width: 100%;
  height: 43px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchTable {
  width: 300px;
}

.TableActions ul {
  display: flex;
}

.TableActions ul li + li {
  margin-left: 8px;
}

/* Table Pagination */
.TableFooter {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #777;
  border-top: 1px solid #f7f7f7;
}

.TableRecordsCount {
  display: flex;
  align-items: center;
}

.PageSize {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.PageSize .FormControl {
  width: 90px;
  height: 30px;
  padding: 0 10px;
  margin-left: 10px;
  color: #777;
}

.TablePagination {
  display: flex;
  align-items: center;
}

.TablePagination .PageCountStat {
  margin-right: 10px;
}

.TablePagination .PageCountStat span {
  margin-left: 10px;
}

.TablePagination .GoToPage {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.TablePagination .GoToPage .FormControl {
  width: 70px;
  height: 30px;
  padding: 0 10px;
  margin-left: 10px;
  color: #777;
}

.PaginationNav button {
  width: 30px;
  height: 30px;
  margin: 0 2px;
}

/* Content Header */
.ContentHeader {
  height: 60px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
}

.DataForm .ContentHeader {
  height: 60px;
  padding: 0 24px;
}

.ContentHeader h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

.ContentHeader .HeaderActions {
  display: flex;
  align-items: center;
}

.ContentHeader .HeaderActions button,
.ContentHeader .HeaderActions div {
  margin-left: 10px;
}

.ContentHeader .HeaderActions svg {
  width: 20px;
  height: 20px;
}

/* Main Content */
.MainContent {
  padding: 10px 24px 20px 24px;
}

/* Data Form */
.DataForm {
  padding: 0;
}

/* Form */
.FormContainer {
  margin: 0 -12px;
}

.FormContainer .FormBody {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}

.FormContainer .FormGroup {
  width: 33.33%;
  min-height: 110px;
  padding: 10px 12px;
  position: relative;
}

.FormContainer .FormBody.Type2 .FormGroup {
  width: 50%;
}

.FormContainer .FormActions {
  width: 33.33%;
  padding: 0 12px;
  display: flex;
}

.FormContainer .FormBody.Type2 .FormActions {
  width: 20%;
}

.FormContainer .FormActions button {
  width: 50%;
}

.FormContainer .FormActions button + button {
  margin-left: 8px;
}

.FormContainer.ModalForm .FormGroup {
  width: 100%;
}

.FormContainer.ModalForm {
  padding: 10px 0;
}

.FormContainer.ModalForm .FormActions {
  width: 100%;
}

.FormContainer.ModalForm .FormActions button {
  width: 100%;
}

.Checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Checkbox .ControlLabel {
  margin: 0;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
}

.Checkbox input {
  margin-right: 10px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Checkbox .Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid #5dc900;
  background-color: transparent;
}

.Checkbox input:checked ~ .Checkmark {
  background-color: #5dc900;
}

.Checkbox .Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.Checkbox input:checked ~ .Checkmark:after {
  display: block;
}

.Checkbox .Checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.FormControl {
  width: 100%;
  height: 40px;
  padding-left: 20px;
  border-radius: 4px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.16);
}

.FormControl:focus,
.FormControl:active,
.FormControl:hover {
  outline: none;
}

/* Form Wizard */
.FormWizard ul {
  height: 60px;
}

.FormWizard ul {
  display: flex;
  align-items: center;
}

.FormWizard ul li + li {
  margin-left: 40px;
}

.FormWizard ul li {
  color: #ccc;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.FormWizard ul li.Active {
  color: #222;
}

.FormWizard ul li span:first-child {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: #fff;
}

.FormWizard ul li span:last-child {
  font-size: 16px;
  font-weight: 400;
}

.FormWizard ul li.Active span:first-child {
  background-color: #222;
  color: #fff;
}
