.SearchBox {
  width: 440px;
  height: 43px;
  padding-left: 14px;
  border-radius: 10px;
  background-color: #f7f7f7;
  display: flex;
}

.SearchBox img {
  width: 15px;
}

.SearchBox.Light {
  background-color: #fff;
}

.FormControl {
  width: 400px;
  padding-left: 10px;
  border: 0;
  background-color: #f7f7f7;
}

.FormControl:focus,
.FormControl:active,
.FormControl:hover {
  outline: none;
}

.SearchBox.Light .FormControl {
  background-color: #fff;
}

.SearchBox ::placeholder {
  color: #444;
}
