
.FormContainer {
    width: 480px;
    padding: 30px 40px !important;
    background-color: #fff;
}

.ErrorMessages {
    margin-top: 43px;
    color: #FF3B3B;
}